import React from 'react';
import './Contact.css';

const home = (props) => {

	return (
		<main className="contact thankyou">
            <h1>Thank you!</h1>
            <div>Your form submission has been received.</div>
		</main>
	);
};

export default home;
