import React from 'react';
import './Locations.css';
import Map from './Map.js';

const home = (props) => {

	return (
		<main className="locations">
			<Map/>
			<div className="details">
				<div className="location">
					<h1>DUMBO</h1>
					<div className="info">
						<div className="address">
							<a href="http://maps.google.com/?q=45 Main Street Suite 1030, Brooklyn, NY 11201" target="_blank" rel="noreferrer">
								45 Main Street
								<br/>
								Suite 1030
								<br/> 
								Brooklyn, NY 11201
							</a>
						</div>
						<a href="tel:718-625-7200">+1 (718) 625 7200</a>
					</div>
				</div>
				<div className="location">
					<h1>SAG</h1>
					<div className="info">
						<div className="address">
							<a href="http://maps.google.com/?q=15 Ackerly Street, Sag Harbor, NY 11963" target="_blank" rel="noreferrer">
								15 Ackerly Street
								<br/>
								Sag Harbor, NY 11963
							</a>
						</div>
						<a href="tel:631-919-5177">+1 (631) 919 5177</a>
					</div>
				</div>
			</div>
		</main>
	);
};

export default home;
