import React from 'react';
import './Team.css'

const home = (props) => {

	return (
		<main className="team">
			<div className="member">
				<img alt="Drew Patrick" src={"images/headshots/drew.jpg"} loading="lazy"></img>
				<div className="bio">
					<h1>Drew Patrick</h1>
					<div className="description">Drew Patrick is the founding member of the firm, and represents leading artists and entities involved with all facets of the media and entertainment industries. For over 20 years, he has negotiated deals for the development, financing, production, acquisition and exploitation of creative content, and counseled clients on a broad range of critical business issues. He has been named a Super Lawyer by Super Lawyers magazine; and as one of The Best Lawyers in America, for his work in motion pictures and television. Prior to founding the firm, Drew practiced law at Debevoise &amp; Plimpton and Frankfurt Kurnit Klein &amp; Selz. He graduated magna cum laude from Dartmouth College and obtained his law degree at Vanderbilt University, where he served as Executive Editor of the Vanderbilt Law Review. He is a member of Phi Beta Kappa and the Order of the Coif.</div>
					<div className="contact">
						<a href="tel:718-625-7200">+1 (718) 625 7200</a>
						<br/>
						<a href="mailto:drew@patmaclaw.com">drew@patmaclaw.com</a>
					</div>
				</div>
			</div>
			<div className="member">
				<img alt="Michael McCormack" src={"images/headshots/michael.jpg"} loading="lazy"></img>
				<div className="bio">
					<h1>Michael McCormack</h1>
					<div className="description">Michael McCormack is a partner at the firm and has extensive experience in all areas of entertainment and media. For over 10 years, Michael has represented clients in a broad range of transactions across the film, television, publishing, podcast, and fashion modeling industries. He has served as production counsel on numerous documentary series and motion pictures and is a frequent public speaker at law schools and industry events. He has been named a Rising Star by Super Lawyers magazine for his work in entertainment. Michael provides pro bono legal assistance to exonerees in connection with their participation in various media projects. Prior to joining the firm, Michael was a Business Affairs Executive at ICM Partners where he negotiated deals across the agency’s business. He graduated with honors from Cornell University and obtained his law degree at Seton Hall University School of Law.</div>
					<div className="contact">
						<a href="tel:718-625-7201">+1 (718) 625 7201</a>
						<br/>
						<a href="mailto:michael@patmaclaw.com">michael@patmaclaw.com</a>
					</div>
				</div>
			</div>
			<div className="member">
				<img alt="Sarah Byrne" src={"images/headshots/sarah.jpg"} loading="lazy"></img>
				<div className="bio">
					<h1>Sarah Byrne</h1>
					<div className="description">Sarah Byrne is an associate at the firm, and assists in the firm’s representation of leading artists and entities in the media and entertainment industries. Prior to joining the firm, she practiced law at Skadden, Arps, Slate, Meagher & Flom, where she worked on a wide variety of corporate and transactional matters. Prior to law school, Sarah was a paralegal at Loeb & Loeb in the firm’s media and technology practice group, and worked as an associate producer at BSTV Entertainment. She graduated with honors from Cornell University and graduated magna cum laude from the University of Pennsylvania Law School, where she served as the Bluebook Editor of the University of Pennsylvania Law Review.</div>
					<div className="contact">
						<a href="tel:718-586-4673">+1 (718) 586 4673</a>
						<br/>
						<a href="mailto:sarah@patmaclaw.com">sarah@patmaclaw.com</a>
					</div>
				</div>
			</div>
			<div className="member">
				<img alt="Maayan McCasland" src={"images/headshots/maayan.jpg"} loading="lazy"></img>
				<div className="bio">
					<h1>Maayan McCasland</h1>
					<div className="description">Maayan McCasland is an associate at the firm, focusing on technology, media and entertainment clients. She has significant experience negotiating and drafting a wide variety of agreements related to all stages of development, production, licensing, and distribution of film, television, music, digital, live stage, book publishing, and software and technology projects. She also performs pre-distribution review of audio and visual content, to identify possible trademark, copyright, privacy, defamation and other issues. Prior to joining the firm, Maayan worked as a freelance photographer for Rolling Stone and other media publications. She is a graduate of Brooklyn Law School and Baruch College.</div>
					<div className="contact">
						<a href="tel:917-428-5021">+1 (917) 428 5021</a>
						<br/>
						<a href="mailto:maayan@patmaclaw.com">maayan@patmaclaw.com</a>
					</div>
				</div>
			</div>
		</main>
	);
};

export default home;
