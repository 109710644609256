import React from 'react';
import './Footer.css';

const footer = (props) => {

	return (
		<footer>
			<div className="container">
				<div className="footer-container">
					<div>
						Patrick+McCormack
						<br/>
						45 Main Street
						<br/>
						Suite 1030
						<br/>
						Brooklyn, NY 11201
						<br/>
						+1 (718) 625 7200
						<div className="copyright">&copy; Patrick+McCormack</div>
					</div>
					<img alt="" className="dot-icon" src={"/images/dots.svg"}/>
				</div>
			</div>
		</footer>
	);
};

export default footer;
