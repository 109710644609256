import React from 'react';
import { NavLink } from 'react-router-dom';
import './MobileMenu.css';

const mobilemenu = (props) => {

    if(!props.show){
        return null
    }
    const closeModal = () => {
        document.querySelector(".modal").classList.add('closing');
        setTimeout(() => {
            props.onClose();
          }, "1000");
    }
	return (
        
        <div className="modal">
            <div className="modal-content">
                <button aria-label="Closes mobile menu" onClick={closeModal}><img alt="" src={"images/close.svg"}></img></button>
                <div className="mobile-menu">
                    <nav>
                        <ul>
                            <li>
                                <NavLink to={"/"} onClick={closeModal}>
                                    <p>About Us</p>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/team"} onClick={closeModal}>
                                    <p>Team</p>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/locations"} onClick={closeModal}>
                                    <p>Locations</p>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/contact"} onClick={closeModal}>
                                    <p>Contact</p>
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
	);
};

export default mobilemenu;