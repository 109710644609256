import React from 'react';
import './Contact.css';

const home = (props) => {

	return (
		<main className="contact">
			<h1>Drop us a line.</h1>
			<div className="form-container">
				<form name="contact" method="post" data-netlify="true" onSubmit="submit" data-netlify-honeypot="bot-field" action="/thank-you">
					<input type="hidden" name="form-name" value="contact"/>
					<div hidden>
						<input name="bot-field"/>
					</div>
					<div className="field-row with-columns">
						<div className="field-column">
							<label htmlFor="first-name">First Name *</label>
							<input id="first-name" type="text" name="first-name" required></input>
						</div>
						<div className="field-column">
							<label htmlFor="last-name">Last Name *</label>
							<input id="last-name" type="text" name="last-name" required></input>
						</div>
					</div>
					<div className="field-row">
						<label htmlFor="email">Email *</label>
						<input id="email" name="email" type="email" required></input>
					</div>
					<div className="field-row">
						<label htmlFor="subject">Subject *</label>
						<input id="subject" name="subject" type="text" required></input>
					</div>
					<div className="field-row">
						<label htmlFor="message">Message *</label>
						<textarea id="message" name="message" required></textarea>
					</div>
					<button type="submit" className="submit">Submit</button>
				</form>
				
			</div>
		</main>
	);
};

export default home;
