import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';
import MobileMenu from './MobileMenu';

export default function Header(){

	const [showMobileMenu, setShowMobileMenu] = useState(false);

	return (
		<header>
			<div className="container">
				<div className="menu">
					<a href="/">
						<img className="logo" src={"images/logo.svg"} alt="Patrick and McCormack logo"/>
					</a>
					<nav>
						<ul>
							<li>
								<NavLink to={"/"}>
									<p>About Us</p>
								</NavLink>
							</li>
							<li>
								<NavLink to={"/team"}>
									<p>Team</p>
								</NavLink>
							</li>
							<li>
								<NavLink to={"/locations"}>
									<p>Locations</p>
								</NavLink>
							</li>
							<li>
								<NavLink to={"/contact"}>
									<p>Contact</p>
								</NavLink>
							</li>
						</ul>
					</nav>
					<button aria-label="Launches mobile menu" onClick={() => setShowMobileMenu(true)} className="hamburger"><img alt="" src={"images/hamburger.svg"}></img></button>
				</div>
			</div>

			<MobileMenu show={showMobileMenu} onClose={() => setShowMobileMenu(false)}/>
		</header>
	);
};

