import React from "react";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";

const id = ["113165217528a697"];
const key = process.env.REACT_APP_GOOGLE_MAPS_API; // PUT GMAP API KEY HERE
const defaultLocation = {lat: 40.9, lng: -73.14};

let zoom;
if(window.innerWidth > 755){
	zoom = 9;
} else if(window.innerWidth > 400) {
	zoom = 8;
} else {
	zoom= 7.9;
}

class Map extends React.Component {
  render() {
    return (
      <div>
		{window.google === undefined ? 
			    <LoadScript googleMapsApiKey={key} mapIds={id}>
					<GoogleMap
					center={defaultLocation}
					zoom={zoom}
					options={{ mapId: "113165217528a697" }}
					mapContainerClassName="mapContainer">
						<MarkerF position={{lat: 40.702770, lng: -73.990578}} clickable={false} icon={"/images/pin.svg"}/>
						<MarkerF position={{lat: 40.996410, lng: -72.287500}} clickable={false} icon={"/images/pin.svg"}/>
				  	</GoogleMap>
			  	</LoadScript> :
				<GoogleMap
					center={defaultLocation}
					zoom={zoom}
					options={{ mapId: "113165217528a697" }}
					mapContainerClassName="mapContainer">
					<MarkerF position={{lat: 40.702770, lng: -73.990578}} clickable={false} icon={"/images/pin.svg"}/>
					<MarkerF position={{lat: 40.996410, lng: -72.287500}} clickable={false} icon={"/images/pin.svg"}/>
				</GoogleMap>
		}
      </div>
    );
  }
}

export default Map;